import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function AGBPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact"
      />
      <section className="p-16">
      <h1>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h1>
      <ol>
      <li>Allgemeines</li>
      </ol>
      <p>Für die Vermittlung von Reisen durch Easy Travel Experts gelten die nachfolgenden Bedingungen. Sie definieren die Rechtverhältnisse zwischen Reiseanmeldern/Kunden und Easy Travel Experts. Es handelt sich hierbei um eine entgeltliche Geschäftsbesorgung gem. § 675 BGB. Für die einzelnen oder zu Pauschalreisen zusammengefügten Reiseleistungen gelten die Beförderungs- und Teilnahmebedingungen der Leistungserbringer sowie deren Tarifbestimmungen. Diese werden auf Wunsch von Easy Travel Experts zur Verfügung gestellt. Sollte Easy Travel Experts in Ausnahmefällen selbst als Reiseveranstalter tätig werden, finden die Regelungen der §§ 651 a ff. BGB Anwendung in der Rechtsbeziehung zwischen ihr und den Reiseanmeldern.</p>
      <ol>
      <li>Vermittlungsvertrag</li>
      </ol>
      <p>Prospekte und sonstige Reisevorschläge in gedruckter oder digitaler Form sind als Aufforderung zur Abgabe eines Vertragsangebotes durch den Reiseanmelder zu verstehen. Mit der Beauftragung konkreter Reiseleistungen wird der Anmelder zum Kunden Easy Travel ExpertsS und der Abschluss eines Reisevermittlungsvertrages wird verbindlich angeboten. Die Beauftragung kann schriftlich, mündlich (auch fernmündlich), digital oder auf andere Weise erfolgen und schließt bei Familien/Partnern etc. alle in der Anmeldung genannten Teilnehmer ein. Der Reiseanmelder haftet für diese wie für sich selbst. Mit der Beauftragung von Easy Travel Experts kommt der Vertrag beim jeweiligen Leistungsträger zustande, ggf. auch bei mehreren Leistungsträgern. Easy Travel Experts behält sich die Annahme des Reisebuchungsauftrages vor. Der Auftrag gilt mit Zusendung der Buchungsbestätigung /Rechnung an den Kunden als angenommen. Touristische Dienstleistungen sind von einem Widerrufsrecht ausgenommen gem. § 312 b III Nr. 6 BGB. Sofern die Buchungsbestätigung/Rechnung Easy Travel ExpertsS vom Buchungsauftrag des Kunden in unzumutbarer Weise abweicht, ist der Kunde berechtigt, sich innerhalb von zehn Tagen schriftlich zu äußern und ggf. seine Nichtannahme ausdrücklich zu erklären. Unterbleibt die Erklärung oder ist sie nicht eindeutig auf die Nichtannahme gerichtet, wird der Vertrag über die Reisevermittlung verbindlich.</p>
      <li>Gruppenreisen</li>
      <p>Als Gruppe nach diesen Bedingungen gelten mindestens drei Personen, die bewusst einen gemeinsamen Reisezweck verfolgen. Der Reiseanmelder handelt grundsätzlich als Vertreter der einzelnen Gruppenmitglieder. Er übernimmt die Anmelderhaftung gegenüber Easy Travel Experts und den Leistungserbringern. Darüber wird er durch Easy Travel Experts gesondert aufgeklärt, wenn er die Reisevermittlung beauftragt. Das Recht, Ansprüche geltend zu machen, bleibt dabei für die einzelnen Reiseteilnehmer bestehen. Diese können jedoch dem Anmelder Vollmacht erteilen.</p>
      <ol>
      <li>Zahlung durch den Kunden und Lieferung der Reisedokumente</li>
      </ol>
      <p>Mit Vertragsschluss wird die Zahlung fällig. Nach Wahl des vermittelten Leistungserbringers kann eine angemessene Anzahlung vereinbart werden. Die Restzahlung ist in diesem Fall spätestens bei Aushändigung der Reiseunterlagen fällig. Die Zahlung erfolgt regelmäßig direkt an den Leistungserbringer, der berechtigt ist, eine gültige Kreditkarte zur Sicherung seiner Ansprüche zu fordern. Ein Anspruch des Kunden auf die Akzeptanz eines bestimmten Zahlungsweges besteht nicht. Wird die Zahlung nicht vollständig oder verspätet geleistet, ist Easy Travel Experts berechtigt, die Reise zu Lasten des Kunden zu stornieren. Entstehende Stornokosten trägt der Kunde. Der Reisevermittlungsauftrag gilt als von Easy Travel Experts erfüllt, wenn die Reisedokumente bereitgestellt werden. Der Kunde hat dafür Sorge zu tragen, dass er elektronische Reiseunterlagen empfangen, speichern und auf Verlagen vorzeigen kann. Auch die Transportgefahr für den Dokumentenversand trägt der Kunde, selbst dann, wenn die Versandkosten von Easy Travel Experts getragen werden. Easy Travel Experts behält sich vor, die Reiseunterlagen per Boten am Abflughafen zu hinterlegen. Die hierfür entstehenden Gebühren des Leistungserbringers trägt der Kunde, zzgl. einer möglichen Servicegebühr von Easy Travel Experts bis 30,00 Euro.</p>
      <ol>
      <li>Rücktritt durch den Kunden, Umbuchung, Teilnehmerwechsel</li>
      </ol>
      <p>Kunden können bis zum Reiseantritt jederzeit vom Reisevermittlungsauftrag zurücktreten. Maßgeblich ist der Zeitpunkt, zu welchem die Rücktrittserklärung beim Leistungserbringer eingeht. Es empfiehlt sich die schriftliche Erklärung mit Zustellungsnachweis. Easy Travel Experts erhebt, soweit nicht anders vereinbart, eine Aufwandsentschädigung zusätzlich zu den Rücktrittsgebühren der Leistungserbringer.</p>
      <p>Bei vermittelten Linienflügen zu Normaltarifen: 25,00 Euro</p>
      <p>Bei vermittelten Linienflügen zu Sondertarifen: 50,00 - 75,00 Euro pro Person, je nachdem, ob der Flugschein bereits ausgestellt wurde.</p>
      <p>Bei vermittelten Charterflügen werden die Stornokosten durch den jeweiligen Leistungserbringer festgelegt.</p>
      <p>Bei Unterkunftsvermittlung: bis sieben Tage vor Reiseantritt 25,00 Euro pro Buchung</p>
      <p>Bei Unterkunftsvermittlung: ab sieben Tage vor Reiseantritt 25,00 Euro pro Buchung zzgl. Kosten einer Übernachtung</p>
      <p>Bei Nichtinanspruchnahme der Leistung: 100%</p>
      <p>Bei Mietwagenvermittlung: bis sieben Tage vor Reiseantritt 25,00 Euro pro Buchung</p>
      <p>Bei Mietwagenvermittlung: ab sieben Tage vor Reiseantritt 50,00 Euro pro Buchung</p>
      <p>Bei Pauschalreisen/Tickets/Veranstaltungen/sonstigen Leistungen richten sich die Stornokosten nach den Bedingungen der jeweiligen Leistungserbringer.</p>
      <ol>
      <li>Umbuchungen</li>
      </ol>
      <p>Eine Umbuchung nach diesen Bedingungen liegt vor, wenn eine oder mehrere wirksam gebuchte Reiseleistungen nach Art, Zeit oder Teilnehmern verändert durchgeführt werden. Die Möglichkeiten richten sich nach den Vorgaben der jeweiligen Leistungserbringer. Easy Travel Experts behält sich grundsätzlich vor, alle durch Umbuchung entstandenen Kosten zzgl. einer Bearbeitungsgebühr von 25,00 Euro pro Person in Rechnung zu stellen.</p>
      <ol>
      <li>Änderungen durch Leistungsträger</li>
      </ol>
      <p>Gemäss der international gültigen luftrechtlichen Bestimmungen sind notwendig werdende Flugänderungen den Leistungsträgern vorbehalten. Dasselbe gilt für Änderungen der Reiseleistungen auf behördliche Anweisung. Solche Änderungen berechtigen den Reiseanmelder nicht zum kostenfreien Reiserücktritt und bedingen auch keinen Ersatzanspruch etwaiger Mehrkosten oder Folgeschäden gegenüber Easy Travel Experts. Sollten Leistungserbringer von ihrem Recht zur Preisanpassung Gebrauch machen, ist Easy Travel Experts berechtigt, die erhöhten Kosten an denn Kunden weiterzuleiten. Beträgt die Preiserhöhung mehr als 10%, ist der Kunde berechtigt, kostenfrei von seiner Reisebuchung zurück zu treten. Weitere Ansprüche bestehen jedoch nicht.</p>
      <ol>
      <li>Haftung</li>
      </ol>
      <p>Easy Travel Experts haftet gegenüber dem Reiseanmelder für eine schuldhafte Pflichtverletzung der Vermittlung, sog. Sorgfalts- oder Informationsfehler, jedoch nicht für die Erbringung der Leistungen, die Leistungsträgern obliegen. Zwischen dem Reiseanmelder und Leistungsträgern ist Easy Travel Experts lediglich vermittelnd tätig und handelt im Auftrag der Leistungserbringer als Erfüllungsgehilfe. Auskünfte gleich welcher Art erfolgen nach bestem Wissen und Gewissen ohne Gewähr.</p>
      <p>Die Haftung aus dem Vermittlungsvertrag ist bezüglich aller Schäden des Kunden, die nicht Körperschäden sind, auf den dreifachen Wert der vermittelten Reiseleistung beschränkt, soweit Easy Travel Experts den Schaden des Kunden weder vorsätzlich noch grob fahrlässig herbeigeführt hat oder Easy Travel Experts verantwortlich ist für den durch einen Erfüllungsgehilfen verursachten Schaden.</p>
      <ol>
      <li>Haftung aus Reisevertrag</li>
      </ol>
      <p>Sollte Easy Travel Experts als Reiseveranstalter gelten, bezieht sich die Haftung abweichend von Nr. 6 auf:</p>
      <p>Die Richtigkeit der beschriebenen Reiseleistungen</p>
      <p>Die gewissenhafte Reisevorbereitung</p>
      <p>Die sorgfältige Auswahl und Überwachung der Leistungserbringer</p>
      <p>Die zeitnahe Unterrichtung des Reiseanmelders über erhebliche Änderungen/Abweichungen von Reiseleistungen gegenüber der Reisebeschreibung.</p>

      <p>Werden für Easy Travel Experts Erfüllungsgehilfen tätig, haftet Easy Travel Experts nur dann, wenn auch am Leistungsort eine Sorgfaltspflichtverletzung durch diese Personen gegeben wäre. Leichte Fahrlässigkeit und unerlaubte Vorsatzhandlung der Erfüllungsgehilfen bedingen keinen Haftungsanspruch Easy Travel Experts. Ob ein Reisemangel vorherrscht, richtet sich nach der allgemeinen Verkehrsanschauung am jeweiligen Leistungsort. Reisemängel wegen höherer Gewalt oder sonstiger von Easy Travel Experts nicht zu vertretende Faktoren ziehen keinen Haftungsanspruch der Reisenden nach sich und entsprechende Mehrkosten gehen zu Lasten des Kunden.</p>
      <p>Kann eine Leistung nicht erbracht werden, wird aber durch eine gleich- oder höherwertige Leistung ersetzt, so dass der Gesamteindruck der Reise nicht wesentlich beeinträchtigt wird, ist die Haftung Easy Travel ExpertsS ausgeschlossen. Bei Minderleistung kann der Kunde eine Reduzierung des Reisepreises verlangen, wenn die Annahme der Leistung unbeschadet vorliegender Mängel zumutbar ist oder die Leistung tatsächlich nicht erbracht werden kann aus Gründen, die weder Easy Travel Experts noch die beauftragten Leistungserbringer zu vertreten haben.</p>
      <p>Sofern erhebliche Mängel vorliegen, die Annahme der Leistung dem Kunden nicht zumutbar ist und er erfolglos Abhilfe verlangt hat, kann der Kunde die Aufhebung des Reisevertrages fordern. Der Kunde schuldet Easy Travel Experts den Teil des Reisepreises für bereits beanspruchte Leistungen, sofern diese nicht gänzlich wertlos waren. Easy Travel Experts schuldet die Rückführung des Kunden adäquat zur eigentlich gebuchten Leistung.</p>
      <p>Liegt eine Beeinträchtigung der gesamten Reise vor aus Gründen, die Easy Travel Experts zu vertreten hat, steht dem Kunden ein Anspruch auf Wandlung, Minderung oder Schadensersatz zu.</p>
      <p>Angaben zur Beförderung liegen in der Verantwortung der Leistungserbringer und stellen keine Zusicherung Easy Travel Experts dar, es sei denn, dass unrichtige Angaben bekannt waren oder bekannt gewesen sein mussten nach branchenüblicher Sorgfalts-/Informationspflicht. Die Haftung erstreckt sich insoweit nur auf Fälle von Vorsatz oder grober Fahrlässigkeit.</p>
      <p>Die Mängelanzeige des Kunden ist innerhalb eines Monats nach dem vertraglich vereinbarten Ende der Reise bei Easy Travel Experts geltend zu machen.</p>
      <ol>
      <li>Reisevorschriften</li>
      </ol>
      <p>Jeder Reisende ist selbst verantwortlich für die Einhaltung aller gültigen in- und ausländischen Vorschriften zur Ein-, Aus- oder Durchreise sowie bezüglich geltender Gesundheitsvorschriften, Pass- und Visabestimmungen. Sofern es Easy Travel Experts möglich und zumutbar ist, wird Easy Travel Experts den Kunden vor Reiseantritt über wichtige Reisevorschriften oder deren Änderung informieren, übernimmt jedoch keine Gewähr für die Richtigkeit der erteilten Informationen. Easy Travel Experts haftet nicht für die rechtzeitige Erteilung und die Zustellung benötigter Visa durch die ausstellende Institution, wenn der Reiseanmelder Easy Travel Experts mit der Besorgung beauftragt hat, es sei denn, Easy Travel Experts hat die Verzögerung zu vertreten.</p>
      <ol>
      <li>Unwirksamkeit</li>
      </ol>
      <p>Eine eventuelle Unwirksamkeit einzelner Regelungen des Vermittlungsvertrages hat nicht die Unwirksamkeit des kompletten Vertrages zur Folge. Der Vermittlungsvertrag wird durch eine mögliche Unwirksamkeit des vermittelten Vertrages nicht selbst unwirksam. Sollte ein Teil dieser Geschäftsbedingungen unwirksam sein, behalten die übrigen Bedingungen dennoch ihre Wirksamkeit und die unwirksamen Regelungen sind durch solche wirksamen Regelungen zu ersetzen, wie sie dem Zweck der Geschäftsbedingungen am nächsten kommen.</p>
      <ol>
      <li>Gerichtsstand</li>
      </ol>
      <p>Eine Klage gegen Easy Travel Experts ist nur am Geschäftssitz möglich. Für Klagen seitens Easy Travel Experts gegen Kunden ist deren Wohnsitz maßgebend, wenn es sich um Privatpersonen mit bekanntem Wohnsitz im Inland handelt. In allen anderen Fällen ist der Geschäftssitz Easy Travel Experts maßgebend.</p>
      <p>Easy Travel Experts</p>
      <p>Viktoriastr. 3</p>
      <p>58636 Iserlohn</p>
      <p>Steuernummer: 016925587</p>
      <p>Inhaberin: Easy Travel Experts</p>
      <p>Bankverbindung: IBAN DE57 1101 0100 2462 4606 25, BIC SOBKDEBBXXX</p>
      <p>Stand: 30.11.2020</p>
      </section>
    </Layout>
  );
}

export default AGBPage;
